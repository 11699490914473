import {z} from "zod";

export const schema = z.object({
  first_name: z
    .string({required_error: "Please enter your first name."})
    .regex(/^[a-zA-Z]+$/, "Please enter valid first name.")
    .max(50, "First name cannot be longer than 50 characters"),
  last_name: z
    .string({required_error: "Please enter your last name."})
    .regex(/^[a-zA-Z]+$/, "Please enter valid last name.")
    .max(50, "Last name cannot be longer than 50 characters"),
  phone_number: z.string({required_error: "Please enter your mobile number."}),
  email: z
    .string({required_error: "Please enter your email."})
    .email("Please enter valid email."),
  company_name: z.string().optional(),
  country_code: z.string(),
  message: z.string({required_error: "Please enter message."}),
  subject: z.string({required_error: "Please enter subject."}),
});
