import {FormikHelpers} from "formik";
import {z} from "zod";
import {schema} from "./formValidation";

type ContactFormType = z.infer<typeof schema>;

export const initialValues: ContactFormType = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  company_name: "",
  country_code: "+1",
  message: "",
  subject: "",
};

export const HandleSubmit = async (
  values: ContactFormType,
  formikHelpers: FormikHelpers<ContactFormType>,
  errorToast: any,
  successToast: any
) => {
  const {setSubmitting, setValues, setTouched, setErrors} = formikHelpers;
  const {country_code, phone_number, ...rest} = values;
  const payload = {
    ...rest,
    phone_number: `${country_code}${phone_number}`,
  };

  try {
    const response = await fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (!response.ok) throw new Error(data?.message);

    successToast({
      description:
        data?.message || "Your message has been sent. We will get back to you shortly.",
      title: "Message sent.",
    });

    setValues(initialValues);
    setTouched({}); // Reset touched fields
    setErrors({}); // Reset form errors
  } catch (error: any) {
    const {message} = error;
    const errorMessage = message || "Your message cannot be sent. Please try again.";

    errorToast({
      description: errorMessage,
      title: "Error occurred.",
    });
  } finally {
    setSubmitting(false);
  }
};
