"use client";
import {Formik, Form} from "formik";
import {Button, Stack, Heading} from "@chakra-ui/react";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {
  Input,
  PhoneInput,
  TextArea,
  useSuccessToast,
  useErrorToast,
} from "@onemind-services-llc/ui-components";
import {schema} from "./formValidation";
import {initialValues, HandleSubmit} from "./hooks";
import {BUTTON_VARIANTS} from "@/theme/components/button";
import {CONTACT_FORM_CONSTANTS} from "../contactForm.constants";
import {FiArrowUpRight} from "@onemind-services-llc/react-icons-ng/fi";

const FormStructure = () => {
  const validationSchema = toFormikValidationSchema(schema);
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  return (
    <Stack
      borderWidth={"1px"}
      borderColor={"primary.300"}
      borderRadius={"8px"}
      className="brand-shadow"
      p={{base: 4, sm: 8}}
      spacing={6}
      w={"full"}
    >
      <Heading fontSize={"32px"} fontWeight={600}>
        {CONTACT_FORM_CONSTANTS.CONTACT_US_SHORT_NOTE}
      </Heading>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) =>
          HandleSubmit(values, formikHelpers, errorToast, successToast)
        }
        validationSchema={validationSchema}
      >
        {(formik) => {
          const {errors, touched, getFieldProps, handleChange, isSubmitting} = formik;
          return (
            <Form>
              <Stack spacing={6}>
                <Stack direction={{base: "column", md: "row"}} spacing={4} w={"full"}>
                  <Input
                    {...getFieldProps("first_name")}
                    bg={"#E8F4FF"}
                    errorMessage={errors.first_name}
                    isInvalid={Boolean(errors.first_name && touched.first_name)}
                    placeholder={CONTACT_FORM_CONSTANTS.FIRST_NAME}
                    variant={"filled"}
                  />
                  <Input
                    {...getFieldProps("last_name")}
                    bg={"#E8F4FF"}
                    errorMessage={errors.last_name}
                    isInvalid={Boolean(errors.last_name && touched.last_name)}
                    placeholder={CONTACT_FORM_CONSTANTS.LAST_NAME}
                    variant={"filled"}
                  />
                </Stack>
                <Stack direction={{base: "column", md: "row"}} spacing={4} w={"full"}>
                  <Input
                    {...getFieldProps("company_name")}
                    bg={"#E8F4FF"}
                    errorMessage={errors.company_name}
                    isInvalid={Boolean(errors.company_name && touched.company_name)}
                    placeholder={CONTACT_FORM_CONSTANTS.COMPANY_NAME}
                    variant={"filled"}
                  />
                  <Input
                    {...getFieldProps("email")}
                    errorMessage={errors.email}
                    bg={"#E8F4FF"}
                    isInvalid={Boolean(errors.email && touched.email)}
                    placeholder={CONTACT_FORM_CONSTANTS.EMAIL}
                    variant={"filled"}
                  />
                </Stack>
                <Stack direction={{base: "column", md: "row"}} spacing={4} w={"full"}>
                  <PhoneInput
                    {...getFieldProps("phone_number")}
                    bg={"#E8F4FF"}
                    errorMessage={errors.phone_number}
                    isInvalid={Boolean(errors.phone_number && touched.phone_number)}
                    placeholder={CONTACT_FORM_CONSTANTS.PHONE_NO}
                    setCountryCode={(code) =>
                      handleChange({target: {name: "country_code", value: code}})
                    }
                    variant={"filled"}
                  />
                  <Input
                    {...getFieldProps("subject")}
                    bg={"#E8F4FF"}
                    errorMessage={errors.subject}
                    isInvalid={Boolean(errors.subject && touched.subject)}
                    placeholder={CONTACT_FORM_CONSTANTS.SUBJECT}
                    variant={"filled"}
                  />
                </Stack>
                <TextArea
                  {...getFieldProps("message")}
                  bg={"#E8F4FF"}
                  errorMessage={errors.message}
                  isInvalid={Boolean(errors.message && touched.message)}
                  placeholder={CONTACT_FORM_CONSTANTS.MESSAGE}
                  variant={"filled"}
                />
                <Button
                  alignSelf={"end"}
                  isLoading={isSubmitting}
                  size="md"
                  type="submit"
                  iconSpacing={2}
                  rightIcon={<FiArrowUpRight fontSize={"20px"} />}
                  variant={BUTTON_VARIANTS.BRAND}
                >
                  {CONTACT_FORM_CONSTANTS.SEND}
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default FormStructure;
