"use client";
import React from "react";
import Link from "next/link";
import {Center, HStack, Heading, Icon, Stack, SimpleGrid, Text} from "@chakra-ui/react";
import {CONTACT_FORM_CONSTANTS} from "./contactForm.constants";
import Form from "./components/form";

import {CONTACT_INFO_LIST} from "../footer/footer.constants";

const ContactForm = () => {
  return (
    <SimpleGrid columns={{base: 1, md: 2}} spacing={{base: 8, md: 10}}>
      <Form />
      <Center>
        <Stack spacing={6}>
          <Heading fontSize={"32px"} fontWeight={600}>
            {CONTACT_FORM_CONSTANTS.CONTACT_US}
          </Heading>
          <Stack spacing={4}>
            {CONTACT_INFO_LIST.map((contact, index) => {
              return (
                <HStack key={index}>
                  <Icon as={contact.icon} color="primary.200" fontSize={"24px"} />
                  <Text
                    as={Link}
                    fontSize={"17px"}
                    fontWeight={400}
                    href={contact.path}
                    target={contact?.ext_link ? "_blank" : ""}
                    _hover={{color: "primary.200", textDecoration: "underline"}}
                  >
                    {contact.label}
                  </Text>
                </HStack>
              );
            })}
          </Stack>
        </Stack>
      </Center>
    </SimpleGrid>
  );
};

export default ContactForm;
